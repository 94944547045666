<template lang="pug">
v-card(flat, height="100%")
  v-card-title {{ name }}
  v-card-text
    .my-box-container
      .my-box
        div Total Booked
        .my-box-number {{ total_booked }}
      .my-box
        div Total Seats
        .my-box-number {{ total_seats }}
  v-simple-table(dense)
    thead
      tr
        th Name
        th Guests
        th.text-right Time
        th.text-right Deposit
        th.text-right Redeemed
    tbody
      Row(
        v-for="item in items",
        :key="item._id",
        @click="() => $emit('open', item)",
        :item="item",
        :timezone="timezone"
      )
  div(v-if="canceled_items?.length")
    v-divider
    h3.pa-3.red--text Canceled
    v-simple-table.red--text(dense)
      thead
        tr
          th Name
          th Guests
          th.text-right Time
          th.text-right Deposit
          th.text-right Redeemed
      tbody
        Row(
          v-for="item in canceled_items",
          :key="item._id",
          @click="() => $emit('open', item)",
          :timezone="timezone",
          :item="item"
        )
</template>

<script>
import { mapGetters } from "vuex";
import Row from "./Row";

export default {
  components: { Row },
  props: {
    group: { type: Object, default: () => ({}) },
    setting: { type: Object, default: () => ({}) },
  },
  computed: {
    ...mapGetters(["biz"]),
    timezone() {
      return this.biz?.address?.timezone;
    },
    name() {
      return this.group?.name;
    },
    total_booked() {
      return this.group?.items?.filter((o) => o.status >= 0)?.length || 0;
    },
    total_seats() {
      return (
        this.group?.items
          ?.filter((o) => o.status >= 0)
          ?.reduce((acc, o) => acc + o.party_size, 0) || 0
      );
    },
    items() {
      return this.group?.items?.filter((o) => o.status >= 0) || [];
    },
    canceled_items() {
      return this.group?.items?.filter((o) => o.status < 0) || [];
    },
  },
};
</script>

<style lang="sass" scoped>
.my-box-container
  display: flex
  flex-direction: row
  align-items: center
  justify-content: space-evenly

.my-box
  display: flex
  flex-direction: column
  align-items: center
  padding: 0 16px 0 16px
.my-box-number
  font-size: 24px
  font-weight: bold
  color: #333
  margin-top: 10px
  margin-bottom: 10px
</style>