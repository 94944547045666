<template lang="pug">
v-dialog(v-model="dialog", max-width="500px")
  v-card(v-if="item")
    v-card-title
      span {{ item_name }}
      v-spacer
      v-btn(icon, @click="dialog = false")
        v-icon mdi-close
    v-simple-table
      tbody
        tr
          th Name
          td.text-right {{ item.customer.name }}
        tr
          th Phone
          td.text-right {{ item.customer.phone | phone }}
        tr
          th Guests
          td.text-right {{ item.party_size }}
        tr
          th Time
          td.text-right {{ item.time | datetime("smart", timezone) }}
        tr(v-if="item.note")
          th Note
          td.text-right {{ item.note }}
        tr(v-if="item.payment.discount")
          th Discount
          td.text-right {{ -item.payment.discount | currency }}
        tr(v-if="item.payment.subtotal")
          th Subtotal
          td.text-right {{ item.payment.subtotal | currency }}
        tr(v-if="item.payment.tax?.total")
          th Tax
          td.text-right {{ item.payment.tax.total | currency }}
        tr(v-if="item.payment.tip")
          th Tip
          td.text-right {{ item.payment.tip | currency }}
        tr
          th Total
          td.text-right {{ item.payment.total | currency }}
        tr(v-for="charge in item.payment.charges", :key="charge._id")
          th Paid {{ charge.method }}
          td.text-right {{ charge.amount | currency }}
        tr(v-for="refund in item.refunds", :key="refund._id")
          th Refund at {{ refund.time | datetime("smart", timezone) }}
          td.text-right {{ refund.amount | currency }}
            span.ml-2(v-if="refund.refund_fee") ({{ -refund.refund_fee | currency }} fee)
        tr(
          v-for="credit in credits",
          :key="credit._id",
          @click="() => showOrder(credit)",
          role="button",
          :style="getRowStyle(credit.status)"
        )
          th Redeemed {{ credit.created | datetime("smart", timezone) }}
          td.text-right {{ credit.amount | currency }}
    v-card-text
      .link-box.my-2(v-if="item.status >= 0")
        v-btn(color="secondary", small, @click="() => $emit('edit', item)") Edit
        v-btn(color="warning", small, @click="() => $emit('refund', item)") Refund
        v-btn(color="error", small, @click="() => $emit('cancel', item)") Cancel
      v-divider.my-2
      .caption Email: {{ item.customer.email }}
      .caption Created at {{ item.created | datetime("smart", timezone) }}
      .caption ID: {{ item._id }}
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { mapGetters } from "vuex";

export default {
  props: {
    setting: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      dialog: false,
      item: null,
    };
  },
  computed: {
    ...mapGetters(["biz", "booked_credits"]),
    credits() {
      return this.booked_credits.filter((o) => o.booked_table == this.item._id);
    },
    timezone() {
      return this.biz?.address?.timezone;
    },
    item_name() {
      return (
        this.setting?.items?.find((o) => o._id === this.item?.booked_item?.id)
          ?.name || this.item?.booked_item?.name
      );
    },
  },
  methods: {
    open(item) {
      this.item = item;
      this.dialog = true;
    },
    getRowStyle(status) {
      if (!status) return { textDecoration: "line-through", color: "red" };
      return "";
    },
    showOrder(credit) {
      if (!credit?.order) {
        this.$toast.error("No order found");
        return;
      }
      EventBus.$emit("show-order-detail", credit.order);
    },
  },
};
</script>