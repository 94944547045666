<template lang="pug">
v-alert(type="info", prominent)
  v-row(align="center")
    v-col.grow Try the New Back Office
    v-col.shrink
      v-btn(@click="goNow", color="secondary", small) Go Now
</template>

<script>
export default {
  props: {
    customPath: { type: String, default: "" },
  },
  methods: {
    goNow() {
      if (this.customPath) {
        const url = `https://www.selflane.com/admin/${this.customPath}`;
        window.open(url, "_blank");
        return;
      }
      // get the current url
      const fullPath = this.$route.fullPath;
      const url = `https://www.selflane.com/admin/${fullPath}`;
      window.open(url, "_blank");
    },
  },
};
</script>